export const TargetStageIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 12"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <defs>
        <clipPath id="clip0_859_5603">
          <rect width="24" height="12" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_859_5603)">
        <path
          d="M20.71 1.885C20.4799 1.32655 20.0889 0.849136 19.5868 0.51348C19.0846 0.177825 18.494 -0.000910513 17.89 3.488e-06H6.11C5.50584 8.7019e-05 4.91527 0.179305 4.41295 0.514998C3.91064 0.850692 3.51914 1.32779 3.28795 1.88596C3.05675 2.44414 2.99626 3.05833 3.1141 3.65089C3.23194 4.24344 3.52283 4.78776 3.95 5.215L9.84 11.105C10.4129 11.6778 11.1899 11.9996 12 11.9996C12.8101 11.9996 13.5871 11.6778 14.16 11.105L20.05 5.215C20.4776 4.78781 20.7686 4.2432 20.8861 3.65031C21.0036 3.05743 20.9423 2.44299 20.71 1.885ZM18.635 3.8L12.745 9.69C12.6476 9.7904 12.531 9.87022 12.4022 9.92473C12.2733 9.97923 12.1349 10.0073 11.995 10.0073C11.8551 10.0073 11.7166 9.97923 11.5878 9.92473C11.459 9.87022 11.3424 9.7904 11.245 9.69L5.36 3.8C5.21255 3.65217 5.11229 3.4639 5.07191 3.25905C5.03153 3.05419 5.05284 2.84196 5.13314 2.64923C5.21345 2.4565 5.34914 2.29192 5.52303 2.17635C5.69693 2.06078 5.9012 1.99941 6.11 2H17.89C18.0984 2.0004 18.302 2.06248 18.4751 2.17844C18.6482 2.29439 18.7831 2.45901 18.8628 2.65154C18.9425 2.84407 18.9634 3.05589 18.9228 3.26027C18.8823 3.46466 18.7821 3.65247 18.635 3.8Z"
          fill="white"
        />
        <path d="M11.275 9.69L5.36001 3.8C5.21257 3.65217 5.11231 3.4639 5.07192 3.25905C5.03154 3.0542 5.05285 2.84197 5.13316 2.64923C5.21346 2.4565 5.34915 2.29192 5.52305 2.17635C5.69694 2.06078 5.90122 1.99941 6.11001 2H17.89C18.0984 2.0004 18.302 2.06249 18.4751 2.17844C18.6482 2.29439 18.7831 2.45901 18.8628 2.65154C18.9425 2.84407 18.9634 3.05589 18.9229 3.26028C18.8823 3.46466 18.7822 3.65247 18.635 3.8L12.745 9.69C12.5494 9.88359 12.2852 9.99217 12.01 9.99217C11.7348 9.99217 11.4707 9.88359 11.275 9.69Z" />
      </g>
    </svg>
  );
};
