import { type ContractState, type ContractType } from '@paid-ui/enums/contract';
import { ProjectStatus } from '@paid-ui/enums/project';
import { type Address } from '@paid-ui/types/address';
import { request } from '@paid-ui/utils/axios';
import { useQuery } from '@tanstack/react-query';

export type ContractSummary = {
  id: string;
  contractType: ContractType;
  contractState: ContractState;
  commencementDate?: string;
  remainingDays?: number;
  progressPayments?: Array<{
    id: string;
    paid: boolean;
  }>;
};

export type ProjectSummary = {
  id: string;
  projectName: string;
  projectNumber?: string;
  projectAddress: Address;
  projectStatus?: ProjectStatus;
  mainContract?: ContractSummary;
  otherContracts?: ContractSummary[];
};

export type GetProjectListResponse = {
  data: {
    projects: ProjectSummary[];
  };
} | null;

export const useGetProjectList = () => {
  return useQuery({
    refetchOnWindowFocus: true,
    queryKey: ['GetProjectList'],
    queryFn: async () => {
      const { data } = await request.get<GetProjectListResponse>('/projects/list');
      const projects = data?.data.projects ?? [];
      return projects.map(({ projectStatus = ProjectStatus.ACTIVE, ...project }) => ({
        projectStatus,
        ...project,
      }));
    },
  });
};
