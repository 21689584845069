import { isNil } from 'lodash';
import { forwardRef, useEffect, useRef, useState } from 'react';

import { Input, type InputProps } from '../input';

export const currencyWithSignFormatter = Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  maximumFractionDigits: 2,
});

export const currencyFormatter = Intl.NumberFormat('en-AU', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Formats a number as a currency string.
 *
 * @param value - The value to format
 * @param options - Formatting options
 * @param options.withSign - Whether to include the currency sign
 * @param options.gstRate - GST Rate included
 * @returns Formatted currency string
 */
export function formatCurrency(
  value: string | number = '',
  options?: {
    withSign?: boolean;
    gstRate?: number;
  },
) {
  const { withSign = false, gstRate = 0 } = options ?? {};

  if (value === '') {
    return '';
  }

  let valueAsNumber =
    typeof value === 'string' ? Number.parseFloat(value.replaceAll(',', '')) : value;

  if (Number.isNaN(valueAsNumber)) {
    return '';
  }

  if (gstRate) {
    valueAsNumber = Math.round(valueAsNumber * (100 + gstRate)) / 100;
  }

  return withSign
    ? currencyWithSignFormatter.format(valueAsNumber)
    : currencyFormatter.format(valueAsNumber);
}

export interface CurrencyInputProps
  extends Omit<
    InputProps,
    'type' | 'inputMode' | 'step' | 'autoComplete' | 'autoCapitalize' | 'autoCorrect'
  > {
  value?: string | number;
  defaultValue?: string | number;
}

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      value,
      defaultValue,
      min = 0,
      max,
      placeholder = '0.00',
      prefix = '$',
      onBlur,
      onChange,
      onKeyDown,
      className,
      ...restProps
    },
    ref,
  ) => {
    const mountedRef = useRef(false);
    const [displayValue, setDisplayValue] = useState(formatCurrency(value ?? defaultValue));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(min) < 0 && event.currentTarget.value === '-') {
        setDisplayValue(event.currentTarget.value);
        return;
      }
      const valueAsNumber = Number.parseFloat(event.currentTarget.value.replaceAll(',', ''));

      if (Number.isNaN(valueAsNumber)) {
        event.currentTarget.value = '';
        setDisplayValue('');
        return;
      }

      setDisplayValue(event.currentTarget.value);
    };

    const formatInputValue = (inputValue: string) => {
      let newValue = '';
      const valueAsNumber = Number.parseFloat(inputValue);

      if (Number.isNaN(valueAsNumber)) {
        newValue = '';
      } else if (
        (Number(min) >= 0 && valueAsNumber > 0 && valueAsNumber < 0.005) ||
        (Number(min) < 0 && valueAsNumber < 0 && valueAsNumber > -0.005)
      ) {
        newValue = '0.00';
      } else if (min && valueAsNumber < Number(min)) {
        newValue = currencyFormatter.format(Number(min));
      } else if (!isNil(max) && valueAsNumber > Number(max)) {
        newValue = currencyFormatter.format(Number(max));
      } else {
        newValue = currencyFormatter.format(valueAsNumber);
      }

      return newValue;
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value.replaceAll(',', '');
      const newValue = formatInputValue(value);
      event.currentTarget.value = newValue.replaceAll(',', '');
      onBlur?.(event);
      onChange?.(event);
      setDisplayValue(newValue);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (['Tab', 'Enter'].includes(event.key)) {
        event.currentTarget.blur();
      }
      onKeyDown?.(event);
    };

    useEffect(() => {
      if (mountedRef.current) {
        setDisplayValue(formatCurrency(value));
      } else {
        mountedRef.current = true;
      }
    }, [value]);

    return (
      <Input
        ref={ref}
        value={displayValue}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className={className}
        inputClassName="text-right"
        {...restProps}
        inputMode="decimal"
        min={min}
        step={1}
        max={max}
        placeholder={placeholder}
        autoComplete="off"
        autoCapitalize="none"
        autoCorrect="off"
        prefix={prefix}
        transparentPrefix
      />
    );
  },
);

CurrencyInput.displayName = 'CurrencyInput';
