export const CollapseIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M2.50003 8C2.49902 7.86832 2.52512 7.73783 2.57669 7.61667C2.65245 7.43415 2.78058 7.27817 2.94491 7.16841C3.10925 7.05866 3.30241 7.00005 3.50003 7H21.5C21.6977 7.00017 21.8908 7.05892 22.0551 7.16881C22.2194 7.2787 22.3475 7.43482 22.4231 7.61744C22.4987 7.80005 22.5185 8.00098 22.48 8.19485C22.4415 8.38871 22.3464 8.56682 22.2067 8.70667L13.2067 17.7067C13.0192 17.8939 12.765 17.9991 12.5 17.9991C12.235 17.9991 11.9809 17.8939 11.7934 17.7067L2.79336 8.70667C2.60577 8.51931 2.50026 8.26513 2.50003 8ZM19.0867 9H5.91336L12.5 15.5867L19.0867 9Z" />
    </svg>
  );
};
