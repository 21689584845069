/**
 * Focus to next input when press enter on table inputs
 *
 * @param event KeyboardEvent
 * @param name - Field name
 */
export const handleTableInputKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  name: string,
) => {
  if (event.key === 'Enter') {
    const nextEle = document.getElementsByName(name)[0];
    nextEle?.focus();
  }
};

/**
 * Focus to next row input when press enter on table inputs
 *
 * @param event KeyboardEvent
 * @param name - Field name
 * @param addItemName - button name
 */
export const handleLastInputKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  name: string,
  addItemName = 'addItem',
) => {
  if (event.key === 'Enter') {
    const nextEle = document.getElementsByName(name)[0];
    if (nextEle) {
      nextEle.focus();
    } else {
      const nextEle = document.getElementsByName(addItemName)[0];
      nextEle?.focus();
      setTimeout(() => {
        const nextInput = document.getElementsByName(name)[0];
        nextInput?.focus();
      }, 10);
    }
  }
};
