import { contractTypes } from '@paid-ui/constants/contracts';
import { ContractState } from '@paid-ui/enums/contract';
import { type ContractSummary, type ProjectSummary } from '@paid-ui/queries/project-list';

/**
 * Format contract status on project card.
 *
 * @param contract - Contract summary object
 * @returns Formatted contract status
 */
export function getContractStatus(contract: ContractSummary) {
  switch (contract.contractState) {
    case 'SUBMITTED':
    case 'REJECTED':
    case 'ACCEPTED':
    case 'RESUBMITTED': {
      return 'Draft';
    }

    case 'EXECUTED': {
      return contract.remainingDays ? `${contract.remainingDays}d remain` : 'Signed';
    }

    default:
  }
}

/**
 * Format project status on project card.
 *
 * @param project - Project summary
 * @returns Formatted project status
 */
export function getProjectStatus(project?: ProjectSummary) {
  const { mainContract, otherContracts = [] } = project ?? {};

  if (mainContract) {
    return getContractStatus(mainContract);
  }

  if (otherContracts.length === 1) {
    return getContractStatus(otherContracts[0]!);
  }

  return null;
}

/**
 * Get computed progress payments on project card.
 *
 * @param project - Project summary
 * @returns Progress payments
 */
export function getProgressPayments(project?: ProjectSummary) {
  const { mainContract, otherContracts = [] } = project ?? {};

  if (mainContract) {
    return mainContract?.progressPayments ?? [];
  }

  if (otherContracts.length === 1) {
    return otherContracts?.at(0)?.progressPayments ?? [];
  }

  return [];
}

/**
 * Get contract type on project card.
 *
 * @param project - Project summary
 * @returns Contract type
 */
export function getContractType(project?: ProjectSummary) {
  const { mainContract, otherContracts = [] } = project ?? {};

  if (mainContract) {
    return contractTypes[mainContract.contractType];
  }

  switch (otherContracts.length) {
    case 0: {
      return null;
    }
    case 1: {
      const otherContractType = otherContracts[0]?.contractType;
      return otherContractType ? contractTypes[otherContractType] : null;
    }
    default: {
      return `${otherContracts.length} contracts`;
    }
  }
}

/**
 * Check if all claims are paid on project card.
 *
 * @param project - Project summary
 * @returns True if all claims are paid
 */
export function checkIfAllClaimsPaid(project?: ProjectSummary) {
  const { mainContract, otherContracts = [] } = project ?? {};
  const allContracts = mainContract ? [mainContract, ...otherContracts] : otherContracts;

  return (
    allContracts.length > 0 &&
    allContracts.every((contract) => contract.contractState === ContractState.ALL_PAID)
  );
}

/**
 * Check if actions are enabled on project card.
 *
 * @param project - Project summary
 * @returns True if actions are enabled
 */
export function checkIfActionsEnable(project?: ProjectSummary) {
  const { mainContract, otherContracts = [] } = project ?? {};

  if (mainContract) {
    return mainContract.contractState === ContractState.EXECUTED;
  }

  if (otherContracts.length > 0) {
    return otherContracts?.at(0)?.contractState === ContractState.EXECUTED;
  }

  return false;
}
