import { twMerge } from 'tailwind-merge';

type Props = {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
  className?: string;
};

export const DashboardLayout: React.FC<Props> = ({ sidebar, children, className }) => {
  return (
    <div
      className={twMerge(
        'container z-0 grid flex-1 grid-cols-1 gap-6 px-4 py-5 md:grid-cols-[1fr_280px] lg:grid-cols-[1fr_350px]',
        className,
      )}
    >
      {children}
      {sidebar ? (
        <div className="hidden md:block">
          <div className="sticky top-[74px] flex w-full flex-col gap-y-6">{sidebar}</div>
        </div>
      ) : null}
    </div>
  );
};
