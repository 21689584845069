import * as PopoverPrimitives from '@radix-ui/react-popover';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const Popover = PopoverPrimitives.Root;
export const PopoverTrigger = PopoverPrimitives.Trigger;
export const PopoverClose = PopoverPrimitives.Close;
export const PopoverArrow = PopoverPrimitives.Arrow;
export const PopoverAnchor = PopoverPrimitives.Anchor;

const popoverContentVariants = cva(
  'z-[500] max-h-[--radix-popover-content-available-height] w-[--radix-popover-trigger-width] min-w-[250px] rounded-md border border-grey-mid bg-white shadow-md will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade',
);

export interface PopoverContentProps
  extends PopoverPrimitives.PopoverContentProps,
    VariantProps<typeof popoverContentVariants> {}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>((props, ref) => {
  const { hidden, children, className, ...restProps } = props;

  if (hidden || !children) {
    return null;
  }

  return (
    <PopoverPrimitives.PopoverPortal>
      <PopoverPrimitives.Content
        ref={ref}
        align="start"
        sideOffset={5}
        collisionPadding={10}
        className={twMerge(
          popoverContentVariants({
            className,
          }),
        )}
        {...restProps}
      >
        {children}
      </PopoverPrimitives.Content>
    </PopoverPrimitives.PopoverPortal>
  );
});

PopoverContent.displayName = 'PopoverContent';
