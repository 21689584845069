export const QuickActionsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M11 23.9891C10.8719 23.9896 10.745 23.9647 10.6266 23.9158C10.4231 23.8355 10.2517 23.6906 10.1385 23.5036C10.0253 23.3165 9.97661 23.0975 9.99996 22.8801L10.8833 14.9973H2.99996C2.8131 14.9974 2.62993 14.9453 2.47122 14.8468C2.31251 14.7482 2.1846 14.6072 2.10201 14.4398C2.01942 14.2723 1.98544 14.0851 2.00393 13.8993C2.02242 13.7135 2.09264 13.5367 2.20662 13.3887L12.2066 0.400527C12.3386 0.224269 12.5252 0.0965041 12.7374 0.037084C12.9495 -0.0223362 13.1754 -0.0100848 13.3799 0.0719348C13.5843 0.153954 13.756 0.30115 13.8681 0.490651C13.9802 0.680152 14.0266 0.901349 14 1.11987L13.1166 9.00271H21C21.1868 9.00254 21.37 9.05467 21.5287 9.15321C21.6874 9.25175 21.8153 9.39274 21.8979 9.5602C21.9805 9.72766 22.0145 9.91489 21.996 10.1007C21.9775 10.2864 21.9073 10.4633 21.7933 10.6112L11.7933 23.5994C11.6998 23.7208 11.5796 23.819 11.4421 23.8866C11.3045 23.9542 11.1532 23.9892 11 23.9891ZM5.02996 12.9991H12C12.1411 12.9982 12.2808 13.0272 12.4099 13.0842C12.539 13.1411 12.6545 13.2247 12.749 13.3294C12.8434 13.4342 12.9146 13.5577 12.9579 13.6919C13.0011 13.8261 13.0155 13.9679 13 14.1081L12.39 19.5465L18.97 11.0009H12C11.859 11.0011 11.7197 10.9715 11.591 10.9141C11.4623 10.8567 11.3472 10.7728 11.2533 10.6679C11.1588 10.5636 11.0874 10.4406 11.0437 10.3069C11 10.1732 10.9851 10.0318 11 9.8919L11.6033 4.45351L5.02996 12.9991Z" />
    </svg>
  );
};
