import { Button } from '@paid-ui/components/button';
import { IconButton } from '@paid-ui/components/icon-button';
import { Popover, PopoverContent, PopoverTrigger } from '@paid-ui/components/popover';
import { Separator } from '@paid-ui/components/separator';
import { QuickActionsIcon } from '@paid-ui/icons/quick-actions';
import { saveProjectSummary } from '@paid-ui/models/project';
import { userManager } from '@paid-ui/models/user';
import { type ProjectSummary } from '@paid-ui/queries/project-list';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useSnapshot } from 'valtio/react';

type Props = {
  data: ProjectSummary;
  enabled?: boolean;
};

export const QuickActions: React.FC<Props> = ({ data, enabled }) => {
  const router = useRouter();
  const { isOwner } = useSnapshot(userManager);
  const [open, setOpen] = useState(false);

  const handlePopoverToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen((prevState) => !prevState);
  };

  const handleProjectAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(false);
    saveProjectSummary(data);
    const dialog = event.currentTarget.dataset.dialog;
    router.push(dialog ? `/projects/${data.id}?dialog=${dialog}` : `/projects/${data.id}`);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <IconButton
          size="lg"
          variant="subtle"
          className="text-grey-dark"
          hidden={isOwner || !enabled}
          onClick={handlePopoverToggle}
        >
          <QuickActionsIcon />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent align="end" side="right" className="w-[150px] min-w-[150px]">
        <Button
          size="sm"
          variant="subtle"
          data-dialog="new-variation"
          className="rounded-none text-black hover:bg-grey-light hover:text-blue"
          onClick={handleProjectAction}
        >
          New variation
        </Button>
        <Separator />
        <Button
          size="sm"
          variant="subtle"
          data-dialog="new-claim"
          className="rounded-none text-black hover:bg-grey-light hover:text-blue"
          onClick={handleProjectAction}
        >
          Claim payment
        </Button>
      </PopoverContent>
    </Popover>
  );
};
