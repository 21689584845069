import { type Address } from '@paid-ui/types/address';

export const getFullProjectName = (
  projectName: string,
  projectNumber: string = '',
  address: Address | null = null,
) => {
  const { suburb, state } = address ?? {};
  const suburbState = [suburb, state].filter(Boolean).join(' ');
  const shortProjectName = [projectNumber, projectName].filter(Boolean).join(' · ');
  return [shortProjectName, suburbState].filter(Boolean).join(' · ');
};
