import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>((props, ref) => {
  const { hidden, className, ...restProps } = props;

  if (hidden) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={twMerge(
        'relative h-1 w-full overflow-hidden rounded-sm bg-grey-light after:absolute after:inset-0  after:animate-pulse after:rounded-sm after:bg-grey-mid after:duration-700 after:ease-in-out after:content-[""] after:direction-alternate after:repeat-infinite',
        className,
      )}
      {...restProps}
    />
  );
});

Skeleton.displayName = 'Skeleton';
