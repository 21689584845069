import { formatCurrency } from '@paid-ui/components/currency-input';
import { Panel } from '@paid-ui/components/panel';
import { formatPercentage } from '@paid-ui/components/percentage-input';
import { Progress } from '@paid-ui/components/progress';
import { request } from '@paid-ui/utils/axios';
import { useQuery } from '@tanstack/react-query';
import { cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const currencyVariants = cva('space-y-1.5 rounded bg-grey-light p-5', {
  variants: {
    disabled: {
      true: 'text-grey-tertiary',
    },
  },
});

type GetContractsSummaryResponse = {
  data: {
    incomingContractValues: number;
    outgoingContractValues: number;
  };
} | null;

export interface ContractsSummaryProps extends React.HTMLAttributes<HTMLDivElement> {
  numberOfProjects?: number;
}

export const ContractsSummary = forwardRef<HTMLDivElement, ContractsSummaryProps>(
  ({ numberOfProjects = 0, hidden, className, ...restProps }, ref) => {
    const disabled = numberOfProjects <= 0;

    const { data, isFetching } = useQuery({
      enabled: !disabled,
      refetchOnWindowFocus: true,
      queryKey: ['GetContractsSummary', numberOfProjects],
      queryFn: async () => {
        const { data } = await request.get<GetContractsSummaryResponse>('/contracts/summary');
        return data?.data;
      },
    });

    if (hidden) {
      return null;
    }

    const incomingContractValues = data?.incomingContractValues ?? 0;
    const outgoingContractValues = data?.outgoingContractValues ?? 0;
    const percentage = incomingContractValues
      ? Math.min((outgoingContractValues / incomingContractValues) * 100, 100)
      : 0;

    const roundedPercentage = formatPercentage(percentage, {
      withSign: true,
      decimal: 2,
    });

    return (
      <Panel ref={ref} className={className} {...restProps}>
        <h2>Overview</h2>
        <div className="mt-1 space-y-6">
          <div className="text-xl font-semibold">Contracts</div>
          <dl className="grid grid-cols-1 gap-2.5 sm:grid-cols-2">
            <div className={twMerge(currencyVariants({ disabled }))}>
              <dt>Incoming contracts value</dt>
              <dd className="truncate text-xl font-semibold">
                {isFetching
                  ? 'Loading...'
                  : formatCurrency(incomingContractValues, { withSign: true })}
              </dd>
            </div>
            <div className={twMerge(currencyVariants({ disabled }))}>
              <dt>Outgoing contracts value</dt>
              <dd className="truncate text-xl font-semibold">
                {isFetching
                  ? 'Loading...'
                  : formatCurrency(outgoingContractValues, { withSign: true })}
              </dd>
            </div>
          </dl>
          {data?.incomingContractValues ? (
            <div className="space-y-1">
              <Progress
                value={percentage}
                color={[disabled ? 'grey' : 'green', 'grey']}
                aria-disabled={disabled}
                aria-describedby="contractsSummaryProgressDesc"
              />
              <p
                id="contractsSummaryProgressDesc"
                className={twMerge('text-sm text-grey-dark', disabled ? 'text-grey-tertiary' : '')}
              >
                Outgoing {isFetching || disabled ? '0.00%' : roundedPercentage} of total incoming
                value
              </p>
            </div>
          ) : null}
        </div>
      </Panel>
    );
  },
);

ContractsSummary.displayName = 'ContractsSummary';
