import { UnstyledButton } from '@paid-ui/components/unstyled-button';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';

const tabs = [
  {
    value: 'active',
    label: 'Current',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];

type Props = {
  hidden?: boolean;
  className?: string;
};

export const ProjectFilters: React.FC<Props> = ({ hidden, className }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const activeTab = searchParams.get('tab') ?? 'active';

  if (hidden) {
    return null;
  }

  const handleTabChange: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const value = event.currentTarget.dataset.tab;
    if (!value) return;

    const newSearchParams = new URLSearchParams(searchParams);

    if (value === 'archived') {
      newSearchParams.set('tab', value);
    } else {
      newSearchParams.delete('tab');
    }

    router.push(
      {
        pathname: '/',
        query: newSearchParams.toString(),
      },
      undefined,
      {
        shallow: true,
        scroll: false,
      },
    );
  };

  return (
    <div className={twMerge('grid items-center gap-x-2 lg:justify-center', className)}>
      <div className="grid w-full grid-cols-2 items-center gap-x-1 rounded-[50px] border border-grey-hover bg-grey-light p-1 lg:w-60">
        {tabs.map((tab) => (
          <UnstyledButton
            key={tab.value}
            data-tab={tab.value}
            onClick={handleTabChange}
            className={twMerge(
              'w-auto cursor-pointer justify-center rounded-[46px] p-2.5 text-sm font-medium transition-all duration-150 ease-linear hover:text-blue',
              activeTab === tab.value ? 'bg-white text-blue shadow' : '',
            )}
          >
            {tab.label}
          </UnstyledButton>
        ))}
      </div>
    </div>
  );
};
