import { CollapseIcon } from '@paid-ui/icons/collapse';
import * as Collapsible from '@radix-ui/react-collapsible';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const panelVariants = cva(
  'flex w-full flex-col gap-y-6 rounded-none bg-white p-5 shadow-down sm:rounded-r-2xl sm:rounded-bl-2xl sm:p-[30px] sm:shadow-md',
  {
    variants: {
      nested: {
        true: 'px-0 py-2 shadow-none sm:rounded-none sm:px-0 sm:py-2 sm:shadow-none',
      },
    },
  },
);

export interface PanelProps
  extends VariantProps<typeof panelVariants>,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: React.ReactNode;
  extra?: React.ReactNode;
  defaultOpen?: boolean;
  disabled?: boolean;
  collapsible?: boolean;
  nested?: boolean;
  headerClassName?: string;
}

export const Panel = forwardRef<HTMLDivElement, PanelProps>(
  (
    {
      title,
      extra = null,
      defaultOpen = true,
      disabled,
      collapsible,
      nested,
      hidden,
      className,
      headerClassName,
      children,
      ...restProps
    },
    ref,
  ) => {
    const [open, setOpen] = useState(disabled ? false : defaultOpen);
    const Title = nested ? 'h4' : 'h3';

    useEffect(() => {
      if (disabled) return;
      setOpen(defaultOpen);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    if (hidden) {
      return null;
    }

    if (collapsible) {
      return (
        <Collapsible.Root
          ref={ref}
          open={open}
          onOpenChange={setOpen}
          defaultOpen={disabled ? false : defaultOpen}
          disabled={disabled}
          className={twMerge(
            panelVariants({
              nested,
              className,
            }),
          )}
          {...restProps}
        >
          <Collapsible.Trigger asChild>
            <div
              role="button"
              className={twMerge(
                'group relative flex w-full cursor-pointer appearance-none items-center gap-x-2.5 bg-transparent text-black outline-none [&>svg]:shrink-0',
                disabled ? 'cursor-not-allowed text-grey-tertiary' : '',
                headerClassName,
              )}
            >
              <CollapseIcon className="h-6 w-6 shrink-0 transition-transform duration-150 ease-linear group-data-[state=closed]:-rotate-90" />
              {typeof title === 'string' ? (
                <Title className="text-xl font-semibold">{title}</Title>
              ) : (
                title
              )}
              {extra ? <div className="ml-auto">{extra}</div> : null}
            </div>
          </Collapsible.Trigger>
          <Collapsible.Content asChild>{children}</Collapsible.Content>
        </Collapsible.Root>
      );
    }

    return (
      <section
        ref={ref}
        className={twMerge(
          panelVariants({
            nested,
            className,
          }),
        )}
        {...restProps}
      >
        {typeof title === 'string' ? (
          <Title className={twMerge('text-xl font-semibold', disabled ? 'text-grey-tertiary' : '')}>
            {title}
          </Title>
        ) : (
          title
        )}
        {children}
      </section>
    );
  },
);

Panel.displayName = 'Panel';
