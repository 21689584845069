export const PlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M21.5 11H13.5V3C13.5 2.73478 13.3946 2.48043 13.2071 2.29289C13.0196 2.10536 12.7652 2 12.5 2C12.2348 2 11.9804 2.10536 11.7929 2.29289C11.6054 2.48043 11.5 2.73478 11.5 3V11H3.5C3.23478 11 2.98043 11.1054 2.79289 11.2929C2.60536 11.4804 2.5 11.7348 2.5 12C2.5 12.2652 2.60536 12.5196 2.79289 12.7071C2.98043 12.8946 3.23478 13 3.5 13H11.5V21C11.5 21.2652 11.6054 21.5196 11.7929 21.7071C11.9804 21.8946 12.2348 22 12.5 22C12.7652 22 13.0196 21.8946 13.2071 21.7071C13.3946 21.5196 13.5 21.2652 13.5 21V13H21.5C21.7652 13 22.0196 12.8946 22.2071 12.7071C22.3946 12.5196 22.5 12.2652 22.5 12C22.5 11.7348 22.3946 11.4804 22.2071 11.2929C22.0196 11.1054 21.7652 11 21.5 11Z" />
    </svg>
  );
};
