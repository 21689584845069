export const ProjectIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 51 52"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      {...props}
    >
      <g fill="currentColor" opacity="0.2">
        <path
          opacity="0.6"
          d="M29.75 43.3333H42.5V23.8333H46.75C46.2676 23.8362 45.7992 23.6676 45.4254 23.3566L25.5 4.33325L21.25 6.49992V25.9999H29.75V43.3333Z"
        />
        <path
          opacity="0.65"
          d="M48.0746 19.9766C48.277 20.1411 48.4456 20.3448 48.5704 20.576C48.4434 20.3463 48.2752 20.143 48.0746 19.9766Z"
        />
        <path
          opacity="0.65"
          d="M48.8754 21.5439C48.8823 21.7256 48.8681 21.9074 48.8329 22.0856C48.8681 21.9074 48.8823 21.7256 48.8754 21.5439Z"
        />
        <path
          opacity="0.65"
          d="M48.6697 20.7927C48.7406 20.9515 48.7906 21.119 48.8185 21.2911C48.7906 21.119 48.7406 20.9515 48.6697 20.7927Z"
        />
        <path
          opacity="0.65"
          d="M48.7546 22.3891C48.7546 22.3891 48.7546 22.3386 48.7546 22.3169C48.6842 22.5776 48.5664 22.8226 48.4076 23.0391C48.559 22.8444 48.6764 22.6245 48.7546 22.3891Z"
        />
        <path
          opacity="0.65"
          d="M46.7502 23.8333C47.0289 23.8253 47.3033 23.7615 47.5577 23.6455C47.3043 23.7647 47.0293 23.8287 46.7502 23.8333Z"
        />
        <path
          opacity="0.65"
          d="M48.3505 23.0676C48.1784 23.2628 47.9747 23.4265 47.7484 23.5515C47.9771 23.4302 48.1813 23.266 48.3505 23.0676Z"
        />
        <path d="M48.8112 22.0857C48.8112 22.1579 48.8112 22.2374 48.7687 22.3096C48.7899 22.2301 48.797 22.1579 48.8112 22.0857Z" />
        <path d="M48.8187 21.291C48.8187 21.3777 48.8187 21.4643 48.8541 21.5438C48.8754 21.4571 48.8329 21.3777 48.8187 21.291Z" />
        <path d="M48.5704 20.5762C48.6058 20.6412 48.6341 20.7206 48.6696 20.7928C48.6408 20.7187 48.6077 20.6463 48.5704 20.5762Z" />
        <path d="M48.4076 23.0244L48.3368 23.0894C48.3368 23.0894 48.3934 23.0389 48.4076 23.0244Z" />
        <path d="M47.7487 23.5518C47.6878 23.5889 47.6238 23.6203 47.5574 23.6456C47.6244 23.6217 47.6886 23.5902 47.7487 23.5518Z" />
        <path opacity="0.35" d="M21.25 26H29.75V43.3333H21.25V26Z" fill="#001026" />
        <path d="M10.625 39.0001C10.0614 39.0001 9.52091 38.7718 9.1224 38.3655C8.72388 37.9592 8.5 37.4081 8.5 36.8334V30.3334C8.5 29.7588 8.72388 29.2077 9.1224 28.8014C9.52091 28.395 10.0614 28.1667 10.625 28.1667C11.1886 28.1667 11.7291 28.395 12.1276 28.8014C12.5261 29.2077 12.75 29.7588 12.75 30.3334V36.8334C12.75 37.4081 12.5261 37.9592 12.1276 38.3655C11.7291 38.7718 11.1886 39.0001 10.625 39.0001Z" />
        <path d="M48.875 47.6666H2.125C1.56142 47.6666 1.02091 47.4383 0.622398 47.032C0.223885 46.6257 0 46.0746 0 45.4999C0 44.9253 0.223885 44.3742 0.622398 43.9679C1.02091 43.5615 1.56142 43.3333 2.125 43.3333H48.875C49.4386 43.3333 49.9791 43.5615 50.3776 43.9679C50.7761 44.3742 51 44.9253 51 45.4999C51 46.0746 50.7761 46.6257 50.3776 47.032C49.9791 47.4383 49.4386 47.6666 48.875 47.6666Z" />
        <path d="M24.1758 2.64329C24.5484 2.33009 25.0175 2.16125 25.5004 2.16662C25.538 2.16256 25.576 2.16256 25.6137 2.16662C25.721 2.17586 25.8275 2.19275 25.9324 2.21718H25.9891C26.0397 2.23076 26.0894 2.24764 26.1379 2.26773C26.3146 2.32216 26.4837 2.39986 26.6408 2.49885H26.6904C26.7367 2.5226 26.7817 2.54913 26.8249 2.57829L48.0749 19.9116C48.2774 20.0761 48.4459 20.2799 48.5708 20.5111C48.6062 20.5761 48.6345 20.6555 48.6699 20.7277C48.7408 20.8865 48.7908 21.054 48.8187 21.2261C48.8187 21.3127 48.8187 21.3994 48.8541 21.4789C48.8611 21.6605 48.8468 21.8423 48.8116 22.0205C48.8116 22.0927 48.8116 22.1722 48.7691 22.2444C48.6986 22.5051 48.5809 22.7501 48.422 22.9666L48.3512 23.0316C48.1791 23.2268 47.9755 23.3905 47.7491 23.5155C47.6883 23.5526 47.6243 23.5841 47.5579 23.6094C47.3071 23.7411 47.032 23.8174 46.7504 23.8333C46.2679 23.8362 45.7996 23.6677 45.4258 23.3566L25.5004 7.10663L24.3387 8.05274C23.9649 8.36377 23.4966 8.53232 23.0141 8.5294C22.5739 8.5298 22.1444 8.39078 21.785 8.13154C21.4256 7.8723 21.154 7.50565 21.0078 7.08225C20.8616 6.65884 20.848 6.1996 20.9689 5.76797C21.0897 5.33634 21.3391 4.95363 21.6824 4.67273L24.1758 2.64329Z" />
        <path d="M10.9939 16.1633C10.8197 15.9406 10.6902 15.685 10.613 15.4111C10.5358 15.1373 10.5123 14.8506 10.5439 14.5675C10.5755 14.2845 10.6615 14.0105 10.7971 13.7613C10.9326 13.5122 11.1151 13.2927 11.3339 13.1156L15.7752 9.50446C16.2182 9.19461 16.7599 9.06736 17.2912 9.14831C17.8226 9.22926 18.3041 9.51241 18.6389 9.94077C18.9738 10.3691 19.1371 10.9109 19.0959 11.4571C19.0548 12.0032 18.8123 12.5132 18.4172 12.8845L13.976 16.4956C13.6002 16.803 13.1332 16.971 12.6514 16.9722C12.3334 16.9725 12.0193 16.8999 11.7325 16.7599C11.4456 16.6199 11.1932 16.416 10.9939 16.1633Z" />
        <path d="M2.12549 21.551C2.13839 21.4009 2.16931 21.2529 2.21757 21.1104C2.24024 20.981 2.2783 20.8549 2.33091 20.7349C2.39262 20.6206 2.4637 20.5119 2.5434 20.4099C2.62395 20.2832 2.71907 20.1668 2.82674 20.0632C2.82674 20.0632 2.86924 19.9982 2.90465 19.9766L5.39091 17.9471C5.60851 17.7687 5.85848 17.6357 6.12652 17.5558C6.39456 17.4759 6.67541 17.4507 6.95302 17.4815C7.23064 17.5124 7.49956 17.5987 7.74443 17.7356C7.98929 17.8725 8.20529 18.0573 8.38007 18.2793C8.65017 18.6281 8.80403 19.0557 8.81924 19.4999H10.6255C11.1891 19.4999 11.7296 19.7282 12.1281 20.1345C12.5266 20.5408 12.7505 21.0919 12.7505 21.6666C12.7505 22.2412 12.5266 22.7923 12.1281 23.1986C11.7296 23.6049 11.1891 23.8332 10.6255 23.8332H4.25049C3.95198 23.8328 3.65713 23.7663 3.38632 23.6382C3.31549 23.6382 3.25882 23.5443 3.18799 23.501C2.9965 23.3908 2.82222 23.252 2.6709 23.0893C2.6709 23.0893 2.61424 23.0893 2.59299 23.0171C2.54357 22.9392 2.50091 22.857 2.46549 22.7716C2.38637 22.6514 2.31988 22.5231 2.26715 22.3888C2.22186 22.2483 2.19333 22.1028 2.18216 21.9554C2.15579 21.8608 2.13684 21.7642 2.12549 21.6666C2.12549 21.6232 2.12549 21.5943 2.12549 21.551Z" />
      </g>
    </svg>
  );
};
