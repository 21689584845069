import { ConfirmDialog } from '@paid-ui/ui';
import { forwardRef } from 'react';
import { useSnapshot } from 'valtio/react';

import { closeConfirmDialog, confirmDialogManager } from './model';

export const GlobalConfirmDialog = forwardRef<HTMLDivElement>((props, ref) => {
  const { open, details } = useSnapshot(confirmDialogManager);

  return (
    <ConfirmDialog nested ref={ref} open={open} onOpenChange={closeConfirmDialog} {...details} />
  );
});

GlobalConfirmDialog.displayName = 'GlobalConfirmDialog';
