'use client';

import { type ProjectStatus } from '@paid-ui/enums/project';
import { type ProjectSummary } from '@paid-ui/queries/project-list';
import { upperCase } from 'lodash';
import { useSearchParams } from 'next/navigation';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { NewProjectButton } from '../new-project-button';
import { ProjectCard } from '../project-card';
import { ProjectFilters } from '../project-filters';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data?: ProjectSummary[];
  badges?: Record<string, number>;
  loading?: boolean;
}

export const ProjectCards = forwardRef<HTMLDivElement, Props>(
  ({ data = [], badges = {}, loading, hidden, className, ...restProps }, ref) => {
    const searchParams = useSearchParams();

    if (hidden) {
      return null;
    }

    const tab = searchParams.get('tab') ?? 'active';
    const projectStatus = upperCase(tab) as ProjectStatus;
    const filteredData = data.filter((item) => item.projectStatus === projectStatus);

    return (
      <div ref={ref} className={twMerge('space-y-6', className)} {...restProps}>
        <header className="grid grid-cols-[auto_1fr_auto] items-center gap-x-2 gap-y-4">
          <h2 className="col-span-2 text-2xl font-semibold lg:col-span-1">My projects</h2>
          <NewProjectButton hidden={data.length === 0} className="order-2 lg:order-3" />
          <ProjectFilters
            hidden={data.length === 0}
            className="order-3 col-span-3 lg:order-2 lg:col-span-1 "
          />
        </header>
        <div className="grid grid-cols-1 gap-3.5 sm:grid-cols-2 xl:grid-cols-3">
          {loading ? (
            Array.from({ length: 12 }, (_, i) => <ProjectCard key={i} loading={loading} />)
          ) : data.length === 0 ? (
            <ProjectCard />
          ) : filteredData.length === 0 ? (
            <div className="text-grey-dark">No {tab} project yet.</div>
          ) : (
            filteredData.map((project) => (
              <ProjectCard key={project.id} data={project} badge={badges[project.id]} />
            ))
          )}
        </div>
      </div>
    );
  },
);

ProjectCards.displayName = 'ProjectCards';
